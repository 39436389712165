<script setup lang="ts">
interface AccordionProps {
  title: string
  greyBackground?: boolean
}
const props = withDefaults(defineProps<AccordionProps>(), {
  greyBackground: true,
})

const route = useRoute()
const isDefaultOpen = computed(() => {
  const hash = route.hash.substring(1)
  return hash.toLowerCase() === props.title.toLowerCase()
})
</script>

<template>
  <HeadlessDisclosure
    v-slot="{ open }"
    :default-open="isDefaultOpen"
  >
    <div border border-neutral-300 fubex-rounded-lg overflow-hidden>
      <HeadlessDisclosureButton
        px-24px w-full
      >
        <div
          flex gap-18px justify-between items-center cursor-pointer w-full
          paragraph-lg font-600 text-neutral-900
          py-22px text-left
          :data-test="getCypressId('accordion-title')"
        >
          <span>{{ title }}</span>
          <UnoIcon i-fubex-chevron-down :class="open && 'rotate-180 transform'" duration-250 />
        </div>
      </HeadlessDisclosureButton>
      <div v-auto-animate :data-test="getCypressId('accordion-content')">
        <HeadlessDisclosurePanel>
          <div v-auto-animate :class="{ 'text-neutral-500 dark:text-neutral-500': greyBackground }" paragraph-lg font-300 mx-24px py-16px border-t border-neutral-300>
            <slot />
          </div>
        </HeadlessDisclosurePanel>
        <span hidden><slot /></span>
      </div>
    </div>
  </HeadlessDisclosure>
</template>
